import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators'
import { getSelectedAddress, setSelectedAddress } from '@/utils/cookies'
import httpHelper from '@/utils/request'
import store from '@/store'

export interface IAddressState {
  addresses: any[] | null
  selectedAddress: number
}

@Module({ dynamic: true, store, name: 'address' })
class Address extends VuexModule implements IAddressState {
  public addresses: any[] | null = null
  public selectedAddress: number = getSelectedAddress()

  @Mutation
  private SET_ADDRESSES (addresses: any[]) {
    this.addresses = addresses
    if (addresses.length > 0 && this.selectedAddress === 0) {
      this.selectedAddress = addresses[0].addressSysNo
    }
  }

  @Mutation
  private SET_SELECTED_ADDRESS (addressSysNo: number) {
    this.selectedAddress = addressSysNo
  }

  @Mutation
  private CLEAR_ADDRESSES () {
    this.selectedAddress = 0
    this.addresses = null
  }

  @Action({ commit: 'SET_ADDRESSES', rawError: true })
  public async loadAddresses () {
    try {
      const res = await httpHelper.get({
        url: 'IUserHome/Addresses',
        data: {
          isFromShopping: 0
        },
        type: 'apiv5'
      })
      const result = res.data || {}
      return result.addressList || []
    } catch (err) {
      return []
    }
  }

  @Action({ commit: 'SET_SELECTED_ADDRESS', rawError: true })
  public async setAddress (addressSysNo: number) {
    setSelectedAddress(addressSysNo)
    return addressSysNo
  }

  @Action({ commit: 'CLEAR_ADDRESSES', rawError: true })
  public clearAddress () {
    setSelectedAddress(0)
  }

  get currentAddress () {
    if (!this.addresses || !this.selectedAddress) {
      return null
    }
    return this.addresses
      .find(address => address.addressSysNo === this.selectedAddress) ||
      null
  }

  get citySysNo () {
    if (this.currentAddress) {
      return this.currentAddress.citySysNo
    }
    return 2
  }

  get areaSysNo () {
    if (this.currentAddress) {
      return this.currentAddress.areaSysNo
    }
    return 3
  }
}

const AddressModule = getModule(Address)

export default AddressModule
